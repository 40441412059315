export const ExcludedServiceCodesFromPreWorkQuestionnaire = [
  'PA-STOCK',
  'ARRIVALSUPPORT',
  'PA-ARRIVALSUPPORT',
  'PA-AUTO',
  'AUTO',
  'PA-BIO',
  'PA-CF',
  'CALL',
  'PA-GACP-1',
  'PA-LAB-6',
  'PA-I-15',
  'PA-LAB-17',
  'PA-LAB-16',
  'PA-FF',
  'PA-GBL01A',
  'PA-GBL01S',
  'PA-GBR01A',
  'PA-GBR01S',
  'PA-GBL02A',
  'PA-GBL02S',
  'PA-GBR02A',
  'PA-GBR02S',
  'PA-GBL03A',
  'PA-GBL03S',
  'PA-GBR03A',
  'PA-GBR03S',
  'PA-GBL04A',
  'PA-GBL04S',
  'PA-GBR04A',
  'PA-GBR04S',
  'PA-GBL05A',
  'PA-GBL05S',
  'PA-GBR05A',
  'PA-GBR05S',
  'PA-GBL06A',
  'PA-GBL06S',
  'PA-GBR06A',
  'PA-GBR06S',
  'PA-HC',
  'HGR',
  'PA-HF',
  'PA-DE-HR',
  'PA-HHR',
  'PA-IE2000-BOTTLE',
  'PA-GACP-22',
  'PA-GACP-21',
  'PA-GACP-16',
  'PA-GACP-17',
  'PA-GACP-20',
  'PA-GACP-18',
  'PA-GACP-19',
  'PA-GACP-2',
  'PA-GACP-9',
  'PA-GACP-10',
  'PA-GACP-11',
  'PA-GACP-12',
  'PA-GACP-13',
  'PA-GACP-14',
  'PA-GACP-4',
  'PA-GACP-5',
  'PA-GACP-6',
  'PA-GACP-7',
  'PA-GACP-8',
  'BUILDINGSERVICES',
  'PA-JS',
  'PA-LEL01A',
  'PA-LEL01S',
  'PA-LER01A',
  'PA-LER01S',
  'PA-LEL02A',
  'PA-LEL02S',
  'PA-LER02A',
  'PA-LER02S',
  'PA-LEL03A',
  'PA-LEL03S',
  'PA-LER03A',
  'PA-LER03S',
  'PA-LEL04A',
  'PA-LEL04S',
  'PA-LER04A',
  'PA-LER04S',
  'PA-LEL05A',
  'PA-LEL05S',
  'PA-LER05A',
  'PA-LER05S',
  'PA-HLE0-A',
  'PA-HLE0-S',
  'MISC',
  'PA-MONTHLY',
  'NETJETSSERVICEHUB',
  'PA-OC',
  'PA-I-18',
  'PA-SPDK-1000',
  'PA-RN',
  'NUT',
  'PA-RT',
  'PA-HLE01-LA',
  'PA-HLE01-LS',
  'PA-HLE01-RA',
  'PA-HLE01-RS',
  'PA-HLE02-LA',
  'PA-HLE02-LS',
  'PA-HLE02-RA',
  'PA-HLE02-RS',
  'PA-HLE03-LA',
  'PA-HLE03-LS',
  'PA-HLE03-RA',
  'PA-HLE03-RS',
  'PA-HLE04-LA',
  'PA-HLE04-LS',
  'PA-HLE04-RA',
  'PA-HLE04-RS',
  'PA-TVF',
  'PA-WALK',
  'PA-WD',
];

export const ExcludedServiceCodesFromPostWorkQuestionnaire = [
  'PA-STOCK',
  'ARRIVALSUPPORT',
  'PA-ARRIVALSUPPORT',
  'PA-AUTO',
  'AUTO',
  'PA-BIO',
  'PA-CF',
  'CALL',
  'PA-GACP-1',
  'PA-LAB-6',
  'PA-I-15',
  'PA-LAB-17',
  'PA-LAB-16',
  'PA-FF',
  'PA-GBL01A',
  'PA-GBL01S',
  'PA-GBR01A',
  'PA-GBR01S',
  'PA-GBL02A',
  'PA-GBL02S',
  'PA-GBR02A',
  'PA-GBR02S',
  'PA-GBL03A',
  'PA-GBL03S',
  'PA-GBR03A',
  'PA-GBR03S',
  'PA-GBL04A',
  'PA-GBL04S',
  'PA-GBR04A',
  'PA-GBR04S',
  'PA-GBL05A',
  'PA-GBL05S',
  'PA-GBR05A',
  'PA-GBR05S',
  'PA-GBL06A',
  'PA-GBL06S',
  'PA-GBR06A',
  'PA-GBR06S',
  'PA-HC',
  'HGR',
  'PA-HF',
  'PA-DE-HR',
  'PA-HHR',
  'PA-IE2000-BOTTLE',
  'PA-GACP-22',
  'PA-GACP-21',
  'PA-GACP-16',
  'PA-GACP-17',
  'PA-GACP-20',
  'PA-GACP-18',
  'PA-GACP-19',
  'PA-GACP-2',
  'PA-GACP-9',
  'PA-GACP-10',
  'PA-GACP-11',
  'PA-GACP-12',
  'PA-GACP-13',
  'PA-GACP-14',
  'PA-GACP-4',
  'PA-GACP-5',
  'PA-GACP-6',
  'PA-GACP-7',
  'PA-GACP-8',
  'BUILDINGSERVICES',
  'PA-JS',
  'PA-LEL01A',
  'PA-LEL01S',
  'PA-LER01A',
  'PA-LER01S',
  'PA-LEL02A',
  'PA-LEL02S',
  'PA-LER02A',
  'PA-LER02S',
  'PA-LEL03A',
  'PA-LEL03S',
  'PA-LER03A',
  'PA-LER03S',
  'PA-LEL04A',
  'PA-LEL04S',
  'PA-LER04A',
  'PA-LER04S',
  'PA-LEL05A',
  'PA-LEL05S',
  'PA-LER05A',
  'PA-LER05S',
  'PA-HLE0-A',
  'PA-HLE0-S',
  'MISC',
  'PA-MONTHLY',
  'NETJETSSERVICEHUB',
  'PA-OC',
  'PA-I-18',
  'PA-SPDK-1000',
  'PA-RN',
  'NUT',
  'PA-RT',
  'PA-HLE01-LA',
  'PA-HLE01-LS',
  'PA-HLE01-RA',
  'PA-HLE01-RS',
  'PA-HLE02-LA',
  'PA-HLE02-LS',
  'PA-HLE02-RA',
  'PA-HLE02-RS',
  'PA-HLE03-LA',
  'PA-HLE03-LS',
  'PA-HLE03-RA',
  'PA-HLE03-RS',
  'PA-HLE04-LA',
  'PA-HLE04-LS',
  'PA-HLE04-RA',
  'PA-HLE04-RS',
  'PA-TVF',
  'PA-WALK',
  'PA-WD',
];
