import * as crypto from 'crypto';

export const uuidToShortAlphanumeric = (uuid: string): string => {
  // Generate SHA-256 hash of the UUID
  const hash = crypto.createHash('sha256').update(uuid).digest('hex');

  // Encode the hash using Base64
  const base64 = Buffer.from(hash, 'hex').toString('base64');

  // Take the first 12 characters as the unique alphanumeric string
  const shortAlphanumeric = base64.slice(0, 12);

  return shortAlphanumeric;
};
