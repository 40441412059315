import { z } from 'zod';


export function dateRange() {
  return z.object({
    start: z.date(),
    end: z.date(),
  })
}

export type DateRange = z.infer<ReturnType<typeof dateRange>>;